.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  min-width: 300px;
  max-width: 90%;
  text-align: center;
}


.bg-alert {
  
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Preto com 50% de transparência */
    z-index: 1070; /* Certifique-se de que o z-index é menor que o do Alert */

}