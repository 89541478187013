.item-box {
    border: 2px solid #28a745;
    border-radius: 5px;
}

.item-header {
    font-size: 14px;
    border-bottom: 2px solid #28a745;
}

.row p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    
}
