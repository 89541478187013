.themeBGColorPrimary {
  background-color: #388E3C !important;
}
.themeBGColorSecondary {
  background-color: #D6D8D7 !important;
}

.themeTextColorPrimary {
  color: #388E3C !important;  
}

.themeTextColorSecondary {
  color: #D6D8D7 !important;  
}

.theme-bg-green {
  background-color: #006400 !important; /* Verde escuro */
}

.custom-input {
  border: 2px solid #006400 !important;
  border-radius: 5px;
}

.main-header {
  background-color: #FAFAFA;
}

.logo {
  width: 100%;
}

.logo-container {
  max-width: 100%;
}

/* styles.css */
.custom-font-size {
  font-size: 0.75rem; /* Ajuste conforme necessário */
}

.line-green {
  height: 10px;
  background-color: #006400; /* Verde escuro */
}

.line-yellow {
  height: 10px;
  background-color: #FFD700; /* Amarelo ouro */
}

.main-container {
  flex: 1;
  padding-top: 56px; /* Adjust this value based on the height of the header */
  padding-bottom: 56px; /* Padding for the footer */
}

footer {
  background-color: #f8f9fa;
  padding: 10px 0;
  text-align: center;
}

.borderRadius2 {
  border-radius: 3px;
}