/* Estilos para o preloader */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    transition: opacity 0.5s ease;
  }
  
  .preloader.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  /* Contêiner do spinner */
  .spinner {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Texto "Sibe" fixo no centro */
  .spinner-text {
    position: absolute;
    font-size: 1.2rem;
    font-weight: bold;
    color: #388E3C;
    z-index: 1; /* Garante que o texto fique acima do spinner */
  }
  
  /* Borda giratória */
  .spinner-border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid #f3f3f3; /* Borda cinza */
    border-top: 4px solid #388E3C; /* Borda superior colorida */
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  
  /* Animação de rotação */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  